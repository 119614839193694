<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          xl="9"
          lg="8"
          md="7"
          class="d-none d-md-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- tree -->
            <v-img
              class="kenburnsEffect"
              cover
              height="100%"

              src="@/assets/images/misc/reset-password.jpg"
            ></v-img>
          </div>
        </v-col>

        <v-col
          xl="3"
          lg="4"
          md="5"
          class="d-flex align-center auth-bg"
          :style="!isDark ? 'background-color:#efeef0' : null"
        >
          <v-card
            flat
            color="transparent"
          >
            <v-img
              src="@/assets/images/logos/logo-02.png"
              max-width="500"
              alt="logo"
              class="mb-0 mb-md-16"
            ></v-img>
            <v-card-title class="px-0">
              Şifre Sıfırlama
            </v-card-title>
            <v-card-text class="px-0 mt-3">
              <validation-observer ref="observer">
                <validation-provider
                  v-slot="{ errors }"
                  ref="password"
                  tag="div"
                  mode="eager"
                  name="Yeni Şifre"
                  rules="required"
                  vid="confirmation"
                >
                  <v-text-field
                    v-model="newPassword"
                    :background-color="isDark ? '#373351' : 'white'"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    label="Yeni Şifre"
                    filled
                    autocomplete="new-password"
                    class="mb-3 rounded-lg overflow-hidden"
                    :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline "
                    hide-details="auto"
                    :error-messages="errors"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  tag="div"
                  mode="eager"
                  name="Yeni Şifre Tekrar"
                  rules="confirmed:confirmation"
                >
                  <v-text-field
                    v-model="confirmPassword"

                    :type="isConfirmPasswordVisible ? 'text' : 'password'"
                    label="Şifreyi Onayla"

                    filled
                    :background-color="isDark ? '#373351' : 'white'"
                    class="mb-6 rounded-lg overflow-hidden"
                    :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    hide-details="auto"
                    :error-messages="errors"
                    @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                  ></v-text-field>
                </validation-provider>
              </validation-observer>
              <div class="d-flex align-center justify-space-between flex-wrap">
                <!-- forget link -->

                <router-link
                  :to="{name:'auth-login'}"
                  class="ms-3  font-weight-medium ls-n1"
                  :class="isDark ? 'white--text':'primary--text'"
                >
                  Giriş Yap
                </router-link>

                <v-btn
                  rounded
                  color="tertiary"
                  type="submit"
                  class="text-capitalize text-body-2 white--text"
                  x-large
                  :loading="loading"
                  @click="submit"
                >
                  Şifreyi Güncelle
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { mdiChevronLeft, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { postDataToken } from '@utils'
import { onMounted, ref } from '@vue/composition-api'
import Vue from 'vue'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const newPassword = ref(null)
    const confirmPassword = ref(null)
    const loading = ref(false)
    const { isDark } = useAppConfig()
    const { route, router } = useRouter()

    const observer = ref(null)

    onMounted(() => {
      // if (
      //   typeof route.value.query.mode === 'undefined' ||
      //   typeof route.value.query.n === 'undefined' ||
      //   typeof route.value.query.p === 'undefined' ||
      //   typeof route.value.query.uid === 'undefined' ||
      //   typeof route.value.query.uid === 'undefined' ||
      //   typeof route.value.query.accessToken === 'undefined' ||
      //   typeof route.value.query.vc === 'undefined'
      // ) {
      //   router.push({ name: 'auth-login' })
      // }
    })
    const submit = async () => {
      const isValid = await observer.value.validate()
      if (isValid) {
        loading.value = true
        postDataToken({
          method: 'memberPassResetFinal',
          newpass: newPassword.value,
          mode: route.value.query.mode,
          n: route.value.query.n,
          p: route.value.query.p,
          uid: route.value.query.uid,
          vc: route.value.query.vc,
          accessToken: route.value.query.accessToken,
        }).then(response => {
          if (response.error === 1) {
            observer.value.reset()
            newPassword.value = null
            confirmPassword.value = null
            loading.value = false
            Vue.swal({
              title: 'Hata',
              text: response.msg,
              icon: 'warning',
              background: '#FF4C51',
              confirmButtonColor: '#000',
            })
          } else if (response.error === false) {
            router.push({ name: 'auth-login' })
          }
        })
      }
    }

    return {
      isPasswordVisible,
      isConfirmPasswordVisible,
      newPassword,
      confirmPassword,
      isDark,
      submit,
      loading,
      observer,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
